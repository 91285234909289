.pass-data {
  width: 50% !important;
}
.form-title {
  margin: 30% auto;
  position: absolute;
}
.under-line {
  border-bottom: 4px solid var(--hover-bg);
  width: 20%;
  margin: 0 auto;
}

.under-line-small {
  border-bottom: 2px solid var(--hover-bg);
  width: 20%;
  margin: 0 auto;
}
@media (min-width: 241px) and (max-width: 500px) {
  .form-title {
    margin: 10% auto;
  }
}

.choose-form {
  margin: 15px auto;
  border: 4px solid rgb(39, 3, 59);
  padding: 15px 0px;
  border-radius: 15px;
}
label {
  font-weight: 700 !important;
}

@media (min-width: 991px) and (max-width: 1400px) {
  .choose-form {
    margin: 50% auto;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .choose-form {
    padding-bottom: 2em;
  }
}

@media (min-width: 764px) and (max-width: 1400px) {
  .pass-data {
    width: 50% !important;
  }
}

@media (min-width: 0px) and (max-width: 568px) {
  .pass-data {
    width: 80% !important;
  }
}
