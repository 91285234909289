:root {
  --text-black: #000;
  --text-white: #fff;
  --hover-bg: #6d76f7;
}

.NavBarItem {
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.2em;
}
.navbar-logo {
  justify-content: start;
  color: var(--text-black);
}
.navbar-logo:hover {
  text-decoration: none;
  color: var(--text-black);
}
.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-menu {
  display: inline-grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  justify-content: end;
  list-style: none;
  text-align: center;
  width: 80vw;
  margin: 0.5em 0;
}

.nav-li {
  color: var(--text-black);
  text-align: center;
  padding: 0;
}

.nav-li :hover {
  text-decoration: none;
  color: var(--text-white);
  border-radius: 5px;
  background: var(--hover-bg);
  transition: all 0.2s ease-out;
}
.nav-a {
  color: var(--text-black);
  padding: 0.5em 1em;
  text-decoration: none;
  font-weight: 600;
  list-style: none;
}
.nav-login {
  color: var(--text-white);
  background-color: var(--hover-bg);
  border-radius: 5px;
  padding: 0.2em 0.7em;
  font-weight: bold;
}
.nav-login:hover {
  color: var(--hover-bg);
  background-color: var(--text-white);
  border-radius: 5px;
}
@media screen and (max-width: 991px) {
  .NavBarItem {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: auto;
    padding-bottom: 20px;
    position: absolute;
    top: 2.5em;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    height: auto;
  }
  .nav-li {
    width: 100%;
    display: table;
    padding: 10px;
  }
  .nav-li :hover {
    background-color: var(--hover-bg);
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 20%);
    cursor: pointer;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0em;
    right: 1em;
    /*transform: translate(-100% 60%);*/
    cursor: pointer;
    font-size: 1.8em;
  }
}
